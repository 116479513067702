function load_portfolio_images() {
  jQuery('.portfolio .bg-block').each(function(i, obj) {
    var img = jQuery(this).attr('data-bg');

    var item = jQuery(this);

    jQuery('<img src="' + img + '">').load(function() {
      item.css('background-image', 'url(' + img + ')');
      item.addClass('loaded');

      // Add the transitions after so we don't fade in when the image loads
      setTimeout(function() {
        item.addClass('transitions');
      }, 50);
    });
  });
}
load_portfolio_images();





jQuery(document).ready(function() {
  jQuery(document).on('facetwp-refresh', function() {
    jQuery('.facetwp-template').addClass('loading');
  });

  jQuery(document).on('facetwp-loaded', function() {
    jQuery('.facetwp-template').removeClass('loading');
    load_portfolio_images()
  });



  // Portfolio masonry layout
  var $grid = jQuery('.m-grid').masonry({
    // options
    itemSelector: '.m-grid-item',
    percentPosition: true,
    columnWidth: '.m-grid-sizer'
  });

  // layout Masonry after each image loads
  $grid.imagesLoaded().progress( function() {
    $grid.masonry('layout');
  });

  jQuery('.m-grid').featherlightGallery();

});
