(function($) {
  /*
   *  new_map
   *
   *  This function will render a Google Map onto the selected jQuery element
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$el (jQuery element)
   *  @return	n/a
   */

  function new_map($el) {

    // var
    var $markers = jQuery().find('.map-markers');


    // vars
    var args = {
      zoom: 11,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false
    };


    // create map
    var map = new google.maps.Map($el[0], args);


    // add a markers reference
    map.markers = [];


    // add markers
    $markers.each(function() {

      add_marker($(this), map);

    });


    // center map
    //center_map(map);


    // return
    return map;

  }

  /*
   *  add_marker
   *
   *  This function will add a marker to the selected Google Map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$marker (jQuery element)
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function add_marker($marker, map) {

    // var
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    icon = {
      url: '/app/themes/archteam/assets/images/arch-waypoint.png', // url
      scaledSize: new google.maps.Size(50, 80), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(25, 80) // anchor
    };

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      icon: icon,
      map: map
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {

        infowindow.open(map, marker);

      });
    }

  }

  /*
   *  center_map
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function center_map(map) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function(i, marker) {

      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

      bounds.extend(latlng);

    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(11);

    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }

  }

  /*
   *  document ready
   *
   *  This function will render each map when the document is ready (page has loaded)
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	5.0.0
   *
   *  @param	n/a
   *  @return	n/a
   */
  // global var


  $(document).ready(function() {

    var $markers = jQuery('body').find('.marker');

    $('.acf-map').each(function() {
      // create map
      map = new_map($(this));


    map.set('styles', [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0081c6"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#0081c6"
      },
      {
        "lightness": -30
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0081c6"
      },
      {
        "saturation": -20
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0081c6"
      },
      {
        "lightness": -20
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0081c6"
      },
      {
        "lightness": -20
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0081c6"
      },
      {
        "saturation": -5
      },
      {
        "lightness": -15
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]);

    });





    // If no facet included but we have markers, load them.
    if (jQuery('.acf-map').length > 0) {


      // add markers
      if ($markers.length > 0) {
        // add a markers reference
        map.markers = [];

        $('.marker').each(function() {
          add_marker(jQuery(this), map);
          console.log(jQuery(this).attr('data-lng'));
          latlng = new google.maps.LatLng(jQuery(this).attr('data-lat'), jQuery(this).attr('data-lng'));
          map.setZoom(10);
          map.setCenter(latlng);
        });




        var latlng;

        $markers.each(function() {
          add_marker(jQuery(this), map);
          console.log(jQuery(this).attr('data-lng'));
          latlng = new google.maps.LatLng(jQuery(this).attr('data-lat'), jQuery(this).attr('data-lng'));
          map.setZoom(10);
          map.setCenter(latlng);
        });
      }
    }



  });

})(jQuery);
