// Load background images
jQuery('.load-bg-img').each(function(i, obj) {
  var large_img = jQuery(this).attr('data-bg-large');
  var small_img = jQuery(this).attr('data-bg-small');

  var item = jQuery(this);

  var bgImg = new Image();
  bgImg.onload = function() {
    item.css('background-image', 'url(' + bgImg.src + ')');
    item.addClass('loaded');
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = item.attr('data-bg');
  } else if (jQuery(document).width() > 400) {
    bgImg.src = item.attr('data-bg-small');
  } else {
    bgImg.src = item.attr('data-bg-tiny');
  }
});

jQuery('.back-link').on("click", function(e) {
  window.history.back();
  return false;
});

jQuery('body').on("click", ".dropdown-menu", function(e) {
  jQuery(this).parent().is(".show") && e.stopPropagation();
});

jQuery('.select-url select').change(function() {
  location.href = jQuery(this).val();
});


jQuery(document).ready(function() {
  // FAQ system
  jQuery(".faqs .faq h3").on("click", function(e) {
    jQuery(this).parent().find('.answer').toggle();
  });

  jQuery('.answer.collapse').on('hide.bs.collapse', function() {
    jQuery(this).parent().removeClass('showing');
  });

  jQuery('.answer.collapse').on('show.bs.collapse	', function() {
    jQuery(this).parent().addClass('showing');
  })

});


// Navigation
jQuery('.dropdown').on('show.bs.dropdown', function() {
  var elem = jQuery(this).find('.dropdown-menu');
  setTimeout(function() {
    elem.addClass('inplace');
  }, 100);
});
jQuery('.dropdown').on('hide.bs.dropdown', function() {
  jQuery(this).find('.dropdown-menu').removeClass('inplace');
})


// Remove press releases from the dropdown
if (jQuery('select#cat').length > 0) {
  jQuery("select#cat option[value='967']").each(function() {
    jQuery(this).remove();
  });
}



// Bio popups
jQuery(document).ready(function() {
  jQuery('.modal-trigger').on('click', function() {
    var id = jQuery(this).attr('id');
    console.log(jQuery('#' + id + '-content .mtitle').html());
    //jQuery('#' + id + ' .mtitle').html();
    jQuery('.modal .modal-title').html(jQuery('#' + id + '-content .mtitle').html());
    jQuery('.modal .modal-body').html(jQuery('#' + id + '-content .mbody').html());

    jQuery('.modal').modal('show');
  });
});

jQuery(document).ready(function() {
  jQuery('.answer.collapse').on('hide.bs.collapse', function() {
    jQuery(this).parent().find('h3').removeClass('showing');
  });

  jQuery('.answer.collapse').on('show.bs.collapse	', function() {
    jQuery(this).parent().find('h3').addClass('showing');
  })

  jQuery('.nav.collapse').on('hide.bs.collapse', function() {
    jQuery('.navbar-container').removeClass('show');
    jQuery('.navbar-toggler').removeClass('show');
    jQuery('html').removeClass('show-nav');
    setTimeout(function() {
      jQuery('.navbar-container').removeClass('showing');
      jQuery('.navbar-toggler').removeClass('showing');
      jQuery('html').removeClass('showing-nav');
    }, 100);
  });

  jQuery('.nav.collapse').on('show.bs.collapse	', function() {
    jQuery('.navbar-container').addClass('showing');
    jQuery('.navbar-toggler').addClass('showing');
    jQuery('html').addClass('showing-nav');
    setTimeout(function() {
      jQuery('.navbar-container').addClass('show');
      jQuery('.navbar-toggler').addClass('show');
      jQuery('html').addClass('show-nav');
    }, 100);
  });

  jQuery('.the-search-form.collapse').on('show.bs.collapse	', function() {

    setTimeout(function() {
      jQuery('.search-form input.search-field').focus();
      console.log('here');
    }, 220);
  });




  jQuery('.controls-container.collapse').on('hide.bs.collapse', function() {
    jQuery('body').removeClass('no-scroll');
  });

  jQuery('.controls-container.collapse').on('show.bs.collapse	', function() {
    jQuery('body').addClass('no-scroll');
  });

});


// If we have a hero Image
if (jQuery('.page-title').length) {
  var bgImg = new Image();
  bgImg.onload = function() {
    jQuery('.page-title').css('background-image', 'url(' + bgImg.src + ')');
    jQuery('.page-title').addClass('inplace');

    // Home page animations
    setTimeout(function(){
      jQuery(".home-branded-mark").addClass('inplace');
    }, 350);
    setTimeout(function(){
      jQuery(".home-branded-mark").addClass('faded-more');
    }, 1200);
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = jQuery('.page-title').attr('data-bg');
  } else if (jQuery(document).width() > 400) {
    bgImg.src = jQuery('.page-title').attr('data-bg-small');
  } else {
    bgImg.src = jQuery('.page-title').attr('data-bg-tiny');
  }
}






/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        $('.marry-widows h1, .marry-widows h2, .marry-widows h3, .marry-widows p').each(function() {
          var string = $(this).html();
          string = string.replace(/ ([^ ]*)$/, '&nbsp;$1');
          $(this).html(string);
        });


        if (1) {
          var myElement = document.querySelector("header");
          // construct an instance of Headroom, passing the element
          var headroom = new Headroom(myElement, {
            tolerance: {
              down: 11,
              up: 10
            }
          });
          // initialise
          headroom.init();
        }




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.




function getScrollTop() {
  if (typeof pageYOffset !== 'undefined') {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = (D.clientHeight) ? D : B;
    return D.scrollTop;
  }
}



function getHeaderTop(div) {
  var winTop;
  if (!div) {
    winTop = jQuery(window).scrollTop();
  } else {
    winTop = jQuery(div).offset().top;
  }
  var headerTop = winTop;
  if (headerTop > headerHidden) {
    headerTop = headerHidden;
  } else if (headerTop < 0) {
    headerTop = 0;
  }
  return headerTop;
}

// State of the header.
var top_stick = true;
var darker_nav = false;




// Update the navigation based on the width of the viewport.
function updateNav() {

  //
  // Update header background color.
  //
  var from_top = jQuery(window).scrollTop();
  if (from_top > 10 && !darker_nav) {
    jQuery(".header").addClass('scrollnav');
    darker_nav = true;
  } else if (from_top <= 10 && darker_nav) {
    jQuery(".header").removeClass('scrollnav');
    darker_nav = false;
  }
}




jQuery(document).ready(function() {


  // Remove 300ms delay when tapping on touch devices.
  jQuery(function() {
    FastClick.attach(document.body);
  });

  updateNav();
  jQuery(window).scroll(jQuery.throttle(65, updateNav));
  jQuery(window).on("debouncedresize", function(event) {
    // Your event handler code goes here.
    updateNav();
  });

  jQuery('a.list-group-item').click(function(e) {
    e.preventDefault();

    event.preventDefault();
    //calculate destination place
    var dest = 0;
    //dest = jQuery(this.hash).offset().top;
    //dest = jQuery(this.hash).position().top;
    dest = jQuery(this.hash).get(0).offsetTop;

    jQuery('.scrollspy').scrollTop(dest);
    jQuery('a.list-group-item').removeClass('active');
    jQuery('a.list-group-item').removeClass('active');
    jQuery(this).addClass('active');

    setTimeout(function() {
      jQuery(this).addClass('active');
    }, 20);

  });



  if (jQuery('.typed').length > 0) {
    var typed3 = new Typed('.typed', {
      strings: ['', 'design-oriented.', 'collaborative.', 'innovative.', 'productive.', 'committed.'],
      typeSpeed: 20,
      backSpeed: 12,
      backDelay: 2000,
      //startDelay: 500,
      smartBackspace: true, // this is a default
      loop: true
    });
  }


});




/* ================================
 * Map locations
 * ============================= */
jQuery(document).ready(function() {
  jQuery(document).on('facetwp-loaded', function() {

    if ('' == FWP.buildQueryString()) {
      jQuery('.reset-button').addClass('disabled');
      jQuery('.reset-button').attr('disabled', 'disabled');
    } else {
      jQuery('.reset-button').removeClass('disabled');
      jQuery('.reset-button').removeAttr('disabled', 'disabled');
    }

    if (window.FWP_MAP !== undefined) {
      jQuery(".view-on-map").on("click", function(e) {
        var beverage_id = jQuery(this).attr('data-map-location-id');
        var markers = FWP_MAP.get_post_markers(beverage_id);

        FWP_MAP.infoWindow.setContent(markers[0].content);
        FWP_MAP.infoWindow.setPosition(markers[0].position);
        FWP_MAP.infoWindow.open(FWP_MAP.map, markers[0]);
      });

    }
  });

  jQuery(document).on('facetwp-refresh', function() {
    jQuery('.fwp-elements').addClass('loading');
  });

  jQuery(document).on('facetwp-loaded', function() {
    jQuery('.fwp-elements').removeClass('loading');
    //jQuery('.facetwp-radius-dropdown').niceSelect();
  });





});




function scrollToOffset(offset) {

  if (offset > 0) {
    jQuery('html, body').stop().animate({
      scrollTop: offset
    }, 300);
  }
}
